import React from 'react'
import './css/Footer.css'
import logo from '../Images/logo2.0.png'
import fb from '../Images/fb.png';
import insta from '../Images/insta.png';
import twitter from '../Images/twitter.png';
import linkedIn from '../Images/linkedIn.png'
import youtube from '../Images/youTube.png'

const Footer = ({ title }) => {
    return (
        <div className='footer'>
            <div>
                <a href={'/'}>
                    <img src={logo} alt="logo" />
                    <h1>{title}</h1>
                </a>
            </div>
            <div className='connect'>
                <h2>Connect</h2>
                {/* <p>+91-860 845 6962<br />
                    2d/3 chinamalukamali west street., Pathamadai,<br /> Tirunelveli 627453.<br />
                    circuitboxtech@outlook.com
                </p> */}
                <a href='mailto: directors@circuitbox.in'>Mail Us Here</a>
                <div className="socialMedia">
                    <a href="https://www.facebook.com/circuit.box.35" target="_blank" ><img src={fb}></img></a>
                    <a href="https://instagram.com/circuit_box?igshid=YmMyMTA2M2Y=" target="_blank" ><img src={insta}></img></a>
                    <a href="https://www.linkedin.com/in/haj-jamal-faizal-b-97b3aa160" target="_blank" ><img src={linkedIn}></img></a>
                    <a href="#" target="_blank" ><img src={twitter}></img></a>
                    <a href="#https://www.youtube.com/@Trustmeiamanelectricalengineer" target="_blank" ><img src={youtube}></img></a>
                </div>
            </div>
        </div>
    )
}

export default Footer