import React from 'react';
import Header from './Components/Header';
import './App.css';
import Home from './Components/Home';
import Footer from './Components/Footer';

function App() {
  const title = "Circuit Box"
  return (
    <div className="App">
      <Header title={title} />
      <Home />
      <Footer title={title} />
    </div>
  );
}

export default App;

//need to code for mobile screen for less tha 900px