import React from 'react'
import './css/Banner.css';

const Banner = ({ data, direction }) => {
    const style = {
        "display": "flex",
        "flex-direction": `${direction}`,
        '@media screen and (max-width: 950px)': {
            display: "none",
            "flex-direction": "column",
        },
    };
    return (
        <div className='banner' style={style}>
            <div className='bannerContent'>
                <h2>{data.title}</h2>
                <p>{data.body}</p>
            </div>
            <div className='bannerImage'>
                <img src={`/Images/${data.name}.PNG`} alt={data.name}></img>
            </div>
        </div >
    )
}

export default Banner