import './css/Header.css';
import React from 'react'
import logo from '../Images/logo2.0.png'
const Header = ({ title }) => {


  return (
    <header className="Header">
      <img src={logo} alt="logo" />
      <h1><a href={'/'}>{title}</a></h1>
    </header>
  )
}

export default Header