import React from 'react'
import tech from '../Images/tech.PNG';
import './css/Home.css'
import Banner from './Banner';

const Home = () => {
    const data = [
        {
            name: "create",
            title: "Create business apps and website.",
            body: "We are providing more creative and efficient functionalities to enhance your business with best possible way which specifically suits you."
        },
        {
            name: "homeAutomation",
            title: "Home Automations",
            body: "We are specialized in electrical, electronics as well as IT infra, hence we are providing you the best suitable automation solutions.Which will greatly reduce you manual efforts and operating cost."
        },
        {
            name: "keepItSimple",
            title: "Keep it simple",
            body: "Our main motto is to provide the best and customized products and services. \n\n Best the way, Best the result"
        }
    ]
    const onclickHandler = () => {
        console.log("onCLick")
    }
    return (
        <div id="Home">
            <div className='tech'>
                <div>
                    <h2>Tech and Business Solutions.</h2>
                    <div className='subAppsDiv'>
                        <h3>Our Apps</h3>
                        {/* <a className='subApps' href='https://quran-bible.circuitbox.in/' target='_blank'>Quran-Bible App</a> */}
                        <a className='subApps' href='https://quran-bible-app.web.app/' target='_blank'>Quran-Bible App</a>
                        <a className='subApps' href='https://blog.circuitbox.in/' target='_blank'>Blog App</a>
                        {/* <a className='subApps' href='https://quran-bible.circuitbox.in/' target='_blank'>Chat App</a> */}

                    </div>
                </div>
                <div>
                    <img src={tech} alt="tech"></img>
                </div>
            </div>
            <div className='AboutUs'>
                <h2><a href='/'>Circuit Box</a> is a startup looking to change the way of business and automations.</h2>
                <p>we are specialized in customization of websites, apps, and other smart technical solutions of your business as well as we are doing electrical and electronics automation to enhance your infrastructure.</p>
            </div>
            {data.map((val, index) => {
                if (index % 2 === 0) {
                    return <Banner data={val} direction="row-reverse" />
                } else {
                    return <Banner data={val} direction="row" />
                }

            })}

        </div>
    )
}

export default Home